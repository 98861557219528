<template>
  <section class="viewing-room">
    <!-- <Loader :loading="loading" /> -->
    <div class="viewing-room__header">
      <h1 v-if="viewingRoom.name" class="viewing-room__header_title">{{ viewingRoom.name }}</h1>
      <p v-if="viewingRoom.description" class="viewing-room__header_description">
        {{ viewingRoom.description }}
      </p>
    </div>
    <div class="viewing-room__body">
      <router-link
        v-for="(artwork, index) in viewingRoom.artworks"
        :key="`artwork-${index}`"
        :to="`/viewing-room/${$route.params.viewingRoomSlug}/${artwork.slug}`"
        class="viewing-room__body__artwork"
      >
        <img :src="artwork.search_image" :alt="artwork.title" />
        <div class="tooltip">
          <span class="tooltip__explore-title">Click To Explore Art</span>
          <div class="tooltip__group" v-if="artwork.is_show_title">
            <span class="tooltip__group_title">{{ artwork.title }}</span>
          </div>
        </div>
      </router-link>
    </div>
  </section>
</template>
<script>
import Loader from "@/components/Loader.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      pageTitle: "GH - Viewing Room",
    };
  },
  head: {
    title: function () {
      return {
        inner: this.pageTitle,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publicViewingRooms", {
      viewingRoom: "getViewingRoom",
    }),
  },
  async created() {
    this.loading = true;
    const { viewingRoomSlug } = this.$route.params;
    await this.apiGetViewingRoom({ viewingRoomSlug });
    let breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Viewing Room",
        to: `/viewing-room/${viewingRoomSlug}`,
      },
      {
        title: this.viewingRoom.breadcrumb,
        to: `/viewing-room/${viewingRoomSlug}`,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.pageTitle = `GH - ${this.viewingRoom.breadcrumb ?? "Viewing Room"}`;
    this.$emit("updateHead");
    this.loading = false;
  },
  watch: {
    "$route.path": {
      async handler() {
        this.loading = true;
        const { viewingRoomSlug } = this.$route.params;
        await this.apiGetViewingRoom({ viewingRoomSlug });
        let breadcrumbs = [
          {
            title: "Home",
            to: "/",
          },
          {
            title: "Viewing Room",
            to: `/viewing-room/${viewingRoomSlug}`,
          },
          {
            title: this.viewingRoom.breadcrumb,
            to: `/viewing-room/${viewingRoomSlug}`,
          },
        ];
        this.setBreadcrumbs(breadcrumbs);
        this.pageTitle = `GH - ${this.viewingRoom.breadcrumb ?? "Viewing Room"}`;
        this.$emit("updateHead");
        this.loading = false;
      },
    },
  },
  methods: {
    ...mapActions("publicViewingRooms", ["apiGetViewingRoom"]),
    ...mapMutations(["setBreadcrumbs"]),
  },
};
</script>
<style lang="scss" scoped>
.viewing-room {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  &__header {
    margin-top: 15.6rem;
    margin-bottom: 10.4rem;
    padding: 0 16rem;
    @media only screen and (max-width: $md) {
      padding: 0 10rem;
    }
    @media only screen and (max-width: $xs) {
      margin-top: 28.3rem;
      padding: 0 25px;
    }

    &_title {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      color: $white;
      font-size: 6.2rem;
      font-weight: 500;
      line-height: 6.8rem;
      @media only screen and (max-width: $xs) {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
    &_description {
      margin-top: 20px;
      text-align: center;
      font-family: $fontOpenSans;
      color: $white;
      font-size: 2.1rem;
      font-weight: 400;
      line-height: 1.4;
      @media only screen and (max-width: $xs) {
        font-size: 2rem;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 5px;
    row-gap: 5px;
    width: 100%;
    @media only screen and (max-width: $md) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media only screen and (max-width: $xs) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      column-gap: 0;
      row-gap: 0;
      width: 100%;
    }
    &__artwork {
      position: relative;
      height: 100%;
      width: 100%;
      img {
        height: 20vw;
        width: 100%;
        @media only screen and (max-width: $xs) {
          height: 100vw;
        }
      }
      .tooltip {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
        background: linear-gradient(transparent, 35%, rgba(0, 0, 0, 0.9));
        width: 100%;
        height: 100%;
        opacity: 0;

        &__explore-title {
          font-family: $fontBodoni;
          font-size: 1.4rem;
          color: $white;
        }

        &__group {
          display: flex;
          flex-direction: column;
          position: absolute;
          padding: 0rem 2.5rem 2.5rem 2.5rem;
          width: 100%;
          left: 0;
          bottom: 0;
          height: 10rem;
          @media only screen and (max-width: $md) {
            padding: 0rem 2rem 2rem 2rem;
            height: auto;
          }

          &_title {
            font-family: $fontBodoni;
            font-size: 3rem;
            color: $white;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            line-clamp: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            @media only screen and (max-width: $md) {
              font-size: 2.6rem;
            }
            @media only screen and (max-width: $sm) {
              font-size: 2rem;
            }
          }
        }
        @media only screen and (max-width: $sm) {
          opacity: 1;
        }
      }

      &:hover {
        .tooltip {
          opacity: 1;
        }
      }
    }
  }
}
</style>

